<template>
    <div>
        <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="标准常规视频发布" name="1">
                <div>
                    <img src="@/assets/image.png" style="width: 500px;" />
                </div>
                <div class="declare">
                    <div class="declare_tips">
                        <label>文件夹内容说明：</label>
                    </div>

                    <div>
                        <label>tag.txt是标签内容【如果没有标签可以删除该文件】</label>
                    </div>
                    <div class="declare_special">
                        <label>tag.txt 内容格式如下：#滑雪,#单板女孩,#冬天该有的样子</label>
                        <label>每个标签要用英文的,分开</label>
                    </div>
                    <div>
                        <label>xxx.mp4 是要发布的视频</label>
                    </div>
                    <div>
                        <label>今天是蓝色系女孩！！.txt 这个是标题与内容 【如果不需要标题与内容删除该文件】</label>
                    </div>
                    <div class="declare_special">
                        <label>今天是蓝色系女孩！！.txt 的文件名即【今天是蓝色系女孩！！】会作为文章标题</label>
                        <label>txt的内容就是文章的内容</label>
                    </div>
                </div>
            </el-collapse-item>
            <el-collapse-item title="标准常规图文发布" name="2">
                <div>
                    <img src="@/assets/standare_reb.png" style="width: 500px;" />
                </div>
                <div class="declare">
                    <div class="declare_tips">
                        <label>文件夹内容说明：</label>
                    </div>

                    <div>
                        <label>tag.txt是标签内容【如果没有标签可以删除该文件】</label>
                    </div>
                    <div class="declare_special">
                        <label>tag.txt 内容格式如下：#滑雪,#单板女孩,#冬天该有的样子</label>
                        <label>每个标签要用英文的,分开</label>
                    </div>
                    <div>
                        <label>xxx.png 要发布的图片【是图片格式就可以了，文件名随便起】</label>
                    </div>
                    <div>
                        <label>大咖xxxxx.txt 这个是标题与内容 【如果不需要标题与内容删除该文件】</label>
                    </div>
                    <div class="declare_special">
                        <label>大咖xxxxx.txt 的文件名即【大咖xxxxx】会作为文章标题</label>
                        <label>txt的内容就是文章的内容</label>
                    </div>
                </div>
            </el-collapse-item>
            <el-collapse-item title="多视频【不同标题】发布" name="3">
                <div>
                    <img src="@/assets/mul_video_reb.png" style="width: 500px;" />
                </div>
                <div class="declare">
                    <div class="declare_tips">
                        <label>文件夹内容说明：</label>
                    </div>

                    <div>
                        <label>每个视频的文件名会作为标题和标签</label>
                    </div>
                    <div class="declare_special">
                        <label>例如：ssstik.io .mp4</label>
                        <label>ssstik.io这个会作为文章标题</label>
                        <label>#二次元 #动漫 #动画 会作为标签【每个标签直接用空格分隔】</label>
                    </div>
                </div>
            </el-collapse-item>
            <el-collapse-item title="多视频【同一标题内容】发布" name="4">
                <div>
                    <img src="@/assets/mul_video_reb_same.png" style="width: 500px;" />
                </div>
                <div class="declare">
                    <div class="declare_tips">
                        <label>文件夹内容说明：</label>
                    </div>

                    <div>
                        <label>视频文件名称可以随便写</label>
                    </div>

                    <div>
                        <label>tag.txt是标签内容【如果没有标签可以删除该文件】</label>
                    </div>
                    <div class="declare_special">
                        <label>tag.txt 内容格式如下：#滑雪,#单板女孩,#冬天该有的样子</label>
                        <label>每个标签要用英文的,分开</label>
                    </div>
                    <div>
                        <label>我是傻逼标题.txt 这个是标题与内容 【如果不需要标题与内容删除该文件】</label>
                    </div>
                    <div class="declare_special">
                        <label>我是傻逼标题.txt 的文件名即【我是傻逼标题】会作为文章标题</label>
                        <label>txt的内容就是文章的内容</label>
                    </div>

                </div>
            </el-collapse-item>
        </el-collapse>

        <div style="background-color: white;">
            <a href="https://space.bilibili.com/483852149" target="_blank">更多视频请关注B站</a>
        </div>
    </div>

</template>



<script>
export default {
    data() {
        return {
            activeNames: ['1']
        };
    },
    methods: {
        handleChange(val) {
            console.log(val);
        }
    }
}
</script>

<style type="text/css">
.declare {
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
}

.declare_tips {
    display: flex;
    flex-direction: column;
    font-size: 20px;
}

.declare_special {
    display: flex;
    flex-direction: column;
    background-color: bisque;
    margin-left: 10px;
}
</style>